import React from "react"
import Link from "shared/components/Link"
import Heading from "mill/components/Heading"
import Imgix from "mill/components/Imgix"
import { paleBlue } from "mill/utils/colors"
import {
  Grid,
  SplitContainer,
  SplitHalf,
  ActionableWrapper
} from "mill/utils/shared/core"
import currentUserLoaded from "mill/containers/CurrentUserLoaded"
import { useQuery } from "@apollo/client"
import Loading from "mill/components/Loader"
import FETCH_CAMPAIGNS_AVAILABLE from "mill/graphql/FetchCampaignsAvailable"
import usePageTitle from "mill/hooks/usePageTitle"
import styled from "styled-components"

const CampaignCard = styled(Link)`
  display: block;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 2rem 2rem;
  border-left: 5px solid #e9e9e9;
  ${props =>
    props.embed == "1" &&
    `border-left: 5px solid ${props.theme.colors.primary};`} ${props =>
    props.burst == "1" &&
    `border-left: 5px solid ${props.theme.colors.tertiary};`}
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 2.5rem;
  color: rgb(142, 144, 145);

  h3 {
    font-size: 1.6rem;
    color: rgb(109, 111, 113);
    font-weight: normal;
    margin: 0;
  }

  em {
    color: rgb(142, 144, 145);
    font-size: 1.3rem;
    display: block;
    font-style: normal;
  }

  small {
    color: rgb(183, 186, 189);
    font-size: 1rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  strong {
    color: ${props => props.theme.colors.primary};
    display: block;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  &:hover {
    top: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1.5);
  }
`

const CampaignsAvailable = () => {
  usePageTitle("Campaigns Available")
  const { loading, error, data } = useQuery(FETCH_CAMPAIGNS_AVAILABLE)

  if (loading) return <Loading />
  if (error) return <p>Error</p>
  const activeCount = data.viewer.activeCampaignMemberships.length

  return (
    <Grid flow={"column"}>
      <SplitContainer>
        <SplitHalf hasImage backgroundColor={paleBlue}>
          <Imgix
            srcPath={"static/burst_available.png"}
            parameters={"fit=fill&fill-color=0000"}
            maxWidth={640}
            maxHeight={480}
          />
        </SplitHalf>
        <SplitHalf hasText>
          <div>
            <Heading size={1} rank={1}>
              Campaigns available!
            </Heading>
            <p>
              You are participating in {activeCount} campaigns right now.
              <br /> Click on a campaign to continue.
            </p>
            <ActionableWrapper>
              {data.viewer.activeCampaignMemberships.map(membership => {
                const campaign = membership.campaign
                const mechanism = campaign.mechanism
                const isEmbed = mechanism === "embed"
                const isBurst = mechanism === "burst"
                return (
                  <CampaignCard
                    data-qa={`c-${campaign.id}`}
                    key={membership.id}
                    to={`/c/${campaign.id}`}
                    embed={isEmbed ? 1 : 0}
                    burst={isBurst ? 1 : 0}>
                    <h3>{campaign.name}</h3>
                    <em>
                      {campaign.friendlyStartDate}{" "}
                      {campaign.friendlyCompletionDate &&
                        `- ${campaign.friendlyCompletionDate}`}
                    </em>
                    <small>{campaign.mechanism} campaign</small>
                    <strong>{membership.nextAction}</strong>
                  </CampaignCard>
                )
              })}
            </ActionableWrapper>
          </div>
        </SplitHalf>
      </SplitContainer>
    </Grid>
  )
}

export default currentUserLoaded(CampaignsAvailable)
